import React from 'react';

import { TVacancy, IFilterValue, IActiveFilter } from '../../types';

import VacanciesList from '@/components/vacancies-list';

interface IVacanciesContentProps {
    isSearchStart: boolean,
    filteredVacancies: Array<TVacancy>,
    searchString: string,
    selectedTags: Record<string, IFilterValue>,
    activeFilters: Array<IActiveFilter>,
    onClickTag: (e: React.MouseEvent) => void
}

export const VacanciesContent: React.FC<IVacanciesContentProps> = ({
    filteredVacancies,
    searchString,
    selectedTags,
    activeFilters,
    onClickTag
}) => {
    return (
        <div className="vacancies__wrapper">
            <VacanciesList
                searchString={searchString}
                data={filteredVacancies}
                selectedTags={selectedTags}
                activeFilters={activeFilters}
                onClickTag={onClickTag}
            />
        </div>
    );
};

export default VacanciesContent;
