import React, { FC } from 'react';

import './index.css';

import Tag from '@/components/tag';
import { IActiveFilter } from '@/components/VacanciesPage/types';

interface IProps {
    activeFilters: Array<IActiveFilter>,
    onClickTag: (e: React.MouseEvent<HTMLLIElement>) => void,
    clearTags: () => void
}

export const VacanciesActiveTags: FC<IProps> = ({
    activeFilters,
    onClickTag,
    clearTags
}) => {
    if(!activeFilters.length) {
        return null;
    }

    return (
        <ul className="vacancies__head__tags">
            {activeFilters.map((tag) => (
                <Tag
                    key={tag.id}
                    text={tag.label}
                    strapiId={tag.id}
                    onClickTag={onClickTag}
                    isActive={false}
                    showCrosshair={true}
                    filterType={tag.type}
                />
            ))}

            {activeFilters.length > 0 && (
                <li
                    onClick={clearTags}
                    className="tag tag_light vacancies__head__tags-clear"
                >
                    Очистить все
                </li>
            )}
        </ul>
    );
};

export default VacanciesActiveTags;
