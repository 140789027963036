import React, { FC } from 'react';

import { useClassnames } from '../../hooks/use-classnames';

import './index.css';

interface IProps {
    isChecked: boolean,
    small?: boolean,
    isEmpty?: boolean
}

const GreenCheckbox: FC<IProps> = ({ isChecked, small, isEmpty }) => {
    const cn = useClassnames();

    return (
        <label className={cn('green-checkbox', { 'green-checkbox_active': isChecked, 'green-checkbox_small': small, 'green-checkbox_empty-error': isEmpty })} htmlFor="">
            <div>
                <p />
            </div>
            <input readOnly={true} checked={isChecked} type="checkbox" />
        </label>
    );
};

export default GreenCheckbox;
