import { useState, useEffect } from 'react';

import { TVacancy, IFilters } from '../types';

interface IUseVacanciesDataProps {
    vacanciesList: Array<TVacancy>,
    filters: IFilters,
    searchString: string
}

export const useVacanciesData = ({
    vacanciesList,
    filters,
    searchString
}: IUseVacanciesDataProps) => {
    const [filteredVacancies, setFilteredVacancies] = useState<Array<TVacancy>>(vacanciesList);

    useEffect(() => {
        // Функция для подсчета совпадающих фильтров для вакансии
        const getMatchingFiltersCount = (vacancy: TVacancy): number => {
            let matches = 0;
            const selectedFilters = {
                directions    : Object.keys(filters.directions.selectedItems),
                cities        : Object.keys(filters.cities.selectedItems),
                jobTypes      : Object.keys(filters.jobTypes.selectedItems),
                jobExperiences: Object.keys(filters.jobExperiences.selectedItems),
                tags          : Object.keys(filters.tags.selectedItems)
            };

            // Проверяем направление
            if(selectedFilters.directions.length
                && selectedFilters.directions.includes(String(vacancy.direction?.id))) {
                matches++;
            }

            // Проверяем город
            if(selectedFilters.cities.length
                && vacancy.city
                && selectedFilters.cities.includes(vacancy.city.value)) {
                matches++;
            }

            // Проверяем тип работы
            if(selectedFilters.jobTypes.length
                && vacancy.jobType
                && selectedFilters.jobTypes.includes(String(vacancy.jobType.id))) {
                matches++;
            }

            // Проверяем опыт работы
            if(selectedFilters.jobExperiences.length
                && vacancy.job_experience
                && selectedFilters.jobExperiences.includes(String(vacancy.job_experience.id))) {
                matches++;
            }

            // Проверяем теги
            if(selectedFilters.tags.length && vacancy.tags) {
                const matchingTags = vacancy.tags.filter((tag) => selectedFilters.tags.includes(String(tag.id))
                ).length;
                if(matchingTags > 0) {
                    matches++;
                }
            }

            return matches;
        };

        // Функция для проверки соответствия поисковой строке
        const matchesSearchString = (vacancy: TVacancy): boolean => {
            if(!searchString) {
                return true;
            }

            const searchLower = searchString.toLowerCase().trim();
            return (
                vacancy.title.toLowerCase().includes(searchLower) || vacancy.about.toLowerCase().includes(searchLower) || vacancy.tags?.some(({ value }) => value.toLowerCase().includes(searchLower)
                ) || false
            );
        };

        // Получаем общее количество активных групп фильтров
        const getActiveFilterGroupsCount = (): number => {
            return Object.values(filters).reduce((count, group) => count + (Object.keys(group.selectedItems).length > 0 ? 1 : 0),
                0);
        };

        const newFilteredVacancies = vacanciesList
            .filter((vacancy) => {
                if(vacancy.isSecret) {
                    return false;
                }

                if(!matchesSearchString(vacancy)) {
                    return false;
                }

                // Если нет активных фильтров, показываем все вакансии
                const activeFilterGroups = getActiveFilterGroupsCount();
                if(activeFilterGroups === 0) {
                    return true;
                }

                // Получаем количество совпадений с фильтрами
                const matches = getMatchingFiltersCount(vacancy);

                // Возвращаем true если есть хотя бы одно совпадение
                return matches > 0;
            })
            .sort((a, b) => {
                // Сортируем по количеству совпадений (больше совпадений = выше в списке)
                const activeFilterGroups = getActiveFilterGroupsCount();
                if(activeFilterGroups === 0) {
                    return 0;
                }

                const matchesA = getMatchingFiltersCount(a);
                const matchesB = getMatchingFiltersCount(b);

                return matchesB - matchesA;
            });

        setFilteredVacancies(newFilteredVacancies);
    }, [filters, searchString, vacanciesList]);

    return { filteredVacancies };
};

