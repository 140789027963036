import { useEffect, useState } from 'react';

const HANDLER_TIMEOUT = 300;
const SEARCH_STORAGE_KEY = 'vacancies_search';

export const useVacanciesSearch = (initialSearchString = '') => {
    const [searchString, setSearchString] = useState(initialSearchString);
    const [debouncedSearchString, setDebouncedSearchString] = useState(searchString);
    const [isSearchStart, setSearchStart] = useState<boolean>(false);

    // Load saved search string after mount
    useEffect(() => {
        if(typeof window === 'undefined') {
            return;
        }

        try {
            const savedSearch = localStorage.getItem(SEARCH_STORAGE_KEY);
            if(savedSearch) {
                setSearchString(savedSearch);
                setDebouncedSearchString(savedSearch);
            }
        } catch(e) {
            console.error('Error loading search string:', e);
        }
    }, []);

    // Handle debouncing and saving
    useEffect(() => {
        if(typeof window === 'undefined') {
            return;
        }

        const handler = setTimeout(() => {
            setDebouncedSearchString(searchString);
            try {
                if(searchString) {
                    localStorage.setItem(SEARCH_STORAGE_KEY, searchString);
                } else {
                    localStorage.removeItem(SEARCH_STORAGE_KEY);
                }
            } catch(e) {
                console.error('Error saving search string:', e);
            }
        }, HANDLER_TIMEOUT);

        return () => {
            clearTimeout(handler);
        };
    }, [searchString]);

    const clearSearch = () => {
        if(typeof window === 'undefined') {
            return;
        }
        setSearchString('');
        setDebouncedSearchString('');
        localStorage.removeItem(SEARCH_STORAGE_KEY);
    };

    return {
        searchString,
        setSearchString,
        debouncedSearchString,
        isSearchStart,
        setSearchStart,
        clearSearch
    };
};
