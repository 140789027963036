import React, { useRef } from 'react';
import { isMobile } from 'react-device-detect';

import { VacanciesActiveTags } from '..';
import { IActiveFilter, IFilterValue, ITag } from '../../types';


import { useClassnames } from '@/hooks/use-classnames';

interface IVacanciesHeaderProps {
    searchString: string,
    setSearchString: (value: string) => void,
    availableTags: Array<ITag>,
    selectedTags: Record<string, IFilterValue>,
    onClickTag: (e: React.MouseEvent) => void,
    clearTags: () => void,
    activeFilters: Array<IActiveFilter>
}

export const VacanciesHeader: React.FC<IVacanciesHeaderProps> = ({
    searchString,
    setSearchString,
    onClickTag,
    clearTags,
    activeFilters
}) => {
    const cn = useClassnames();
    const $container = useRef<HTMLInputElement>(null);

    return (
        <div className={cn('vacancies__header')}>
            <div className={cn('vacancies__search')}>
                <input
                    ref={$container}
                    className={cn('vacancies__search-input')}
                    value={searchString}
                    type="text"
                    placeholder={isMobile ? 'Вакансии' : 'Введите текст'}
                    onChange={(e) => setSearchString(e.target.value)}
                />
                <button
                    onClick={() => {
                        if(searchString) {
                            setSearchString('');
                        } else {
                            $container.current?.focus();
                        }
                    }}
                    className={cn('vacancies__search-button')}
                >
                    {searchString ? (
                        <svg
                            width="18" height="18" viewBox="0 0 18 18" fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd" clipRule="evenodd"
                                d="M16.8749 1.13749C16.3874 0.649994 15.5999 0.649994 15.1124 1.13749L8.9999 7.23749L2.8874 1.12499C2.3999 0.637494 1.6124 0.637494 1.1249 1.12499C0.637402 1.61249 0.637402 2.39999 1.1249 2.88749L7.2374 8.99999L1.1249 15.1125C0.637402 15.6 0.637402 16.3875 1.1249 16.875C1.6124 17.3625 2.3999 17.3625 2.8874 16.875L8.9999 10.7625L15.1124 16.875C15.5999 17.3625 16.3874 17.3625 16.8749 16.875C17.3624 16.3875 17.3624 15.6 16.8749 15.1125L10.7624 8.99999L16.8749 2.88749C17.3499 2.41249 17.3499 1.61249 16.8749 1.13749Z"
                                fill="#1F272E"
                            />
                        </svg>
                    ) : (
                        <svg
                            xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                            fill="none"
                        >
                            <path
                                fillRule="evenodd" clipRule="evenodd"
                                d="M9.5 16C13.0899 16 16 13.0899 16 9.5C16 5.91015 13.0899 3 9.5 3C5.91015 3 3 5.91015 3 9.5C3 13.0899 5.91015 16 9.5 16ZM16.176 14.7618C17.3183 13.3145 18 11.4869 18 9.5C18 4.80558 14.1944 1 9.5 1C4.80558 1 1 4.80558 1 9.5C1 14.1944 4.80558 18 9.5 18C11.4869 18 13.3145 17.3183 14.7618 16.176L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L16.176 14.7618ZM8.5 5.5C8.5 4.94772 8.94772 4.5 9.5 4.5C10.8252 4.5 12.0668 5.15255 12.9571 6.04289C13.8475 6.93324 14.5 8.17477 14.5 9.5C14.5 10.0523 14.0523 10.5 13.5 10.5C12.9477 10.5 12.5 10.0523 12.5 9.5C12.5 8.82523 12.1525 8.06676 11.5429 7.45711C10.9332 6.84745 10.1748 6.5 9.5 6.5C8.94772 6.5 8.5 6.05228 8.5 5.5Z"
                                fill="#1F272E"
                            />
                        </svg>
                    )}
                </button>
            </div>

            <VacanciesActiveTags
                activeFilters={activeFilters}
                onClickTag={onClickTag}
                clearTags={clearTags}
            />
        </div>
    );
};
