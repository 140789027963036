import React, { FC, useEffect, useRef } from 'react';

import './index.css';

import Tag from '@/components/tag';
import { useClassnames } from '@/hooks/use-classnames';
import { ITagsList } from '@/types/strapi/vacancies';

interface IProps {
    tags: {
        tags: Array<{
            id: string | number,
            text: string,
            strapiId?: number
        }>,
        selectedItems: Record<string, any>,
        onClickTag: (e: React.MouseEvent) => void
    },
    isAdaptiveWidth?: boolean
}

const ELEMENTS_SPACING = 8;
const CONTAINER_PADDING = 12;

const TagsList: FC<IProps> = ({ tags, isAdaptiveWidth }) => {
    const cn = useClassnames();

    const tagsList = useRef<HTMLUListElement | null>(null);

    const adaptiveWidth = () => {
        if(!tagsList || !tagsList.current || tags.tags.length <= 1) {
            return;
        }

        const containerWidth = tagsList.current.offsetWidth;
        const elements = Array.from(tagsList.current.childNodes) as Array<HTMLElement>;

        let totalWidth = 0;

        elements.forEach((element: HTMLElement) => {
            totalWidth += element.clientWidth + ELEMENTS_SPACING + CONTAINER_PADDING;

            if(totalWidth > containerWidth) {
                element.style.display = 'none';
            } else {
                element.style.display = '';
            }
        });
    };

    useEffect(() => {
        if(isAdaptiveWidth) {
            requestAnimationFrame(adaptiveWidth);
        }
    }, [isAdaptiveWidth]);

    if(!tags.tags || !tags) {
        return null;
    }

    return (
        <ul ref={tagsList} className={cn('tags-list')}>
            {tags.tags.map((tag) => (
                <Tag
                    key={tag.id}
                    text={tag.text}
                    strapiId={String(tag.id)}
                    isActive={Boolean(tags.selectedItems[tag.id])}
                    onClickTag={tags.onClickTag}
                />
            ))}
        </ul>
    );
};

export default TagsList;
