import { useState, useMemo, useEffect } from 'react';

import { IFilters, IFilterValue, IFilterGroup, TVacancy, TDirection } from '../types';

import { useFilterPersistence } from '.';

interface IUseVacanciesFiltersProps {
    initialVacancies: Array<TVacancy>,
    initialDirections: Array<TDirection>,
    initialDirectionParam?: string | null,
    initialTagsParam?: Array<string>
}

const FILTER_URL_MAPPING = {
    tags          : 'tag',
    directions    : 'direction',
    cities        : 'city',
    jobTypes      : 'jobType',
    jobExperiences: 'jobExperience'
} as const;

const URL_FILTER_PARAMS = Object.values(FILTER_URL_MAPPING);

const createEmptyFilterGroup = (): IFilterGroup => ({
    allItems     : {},
    selectedItems: {}
});

const createInitialFilters = (): IFilters => ({
    directions    : createEmptyFilterGroup(),
    cities        : createEmptyFilterGroup(),
    jobTypes      : createEmptyFilterGroup(),
    jobExperiences: createEmptyFilterGroup(),
    tags          : createEmptyFilterGroup()
});

export const useVacanciesFilters = ({
    initialVacancies,
    initialDirections
}: IUseVacanciesFiltersProps) => {
    const { loadFilters, saveFilters, convertStoredFiltersToFilters } = useFilterPersistence();
    const [wasReset, setWasReset] = useState(false);

    // Initialize filters with just the all items, no selected items yet
    const [filters, setFilters] = useState<IFilters>(() => {
        const initialFilters = createInitialFilters();

        // Populate allItems
        initialDirections.forEach((direction) => {
            initialFilters.directions.allItems[direction.id] = {
                id  : direction.id,
                text: direction.header,
                sort: direction.sort
            };
        });

        initialVacancies.forEach((vacancy) => {
            if(vacancy.city) {
                const { value, text } = vacancy.city;
                if(!initialFilters.cities.allItems[value]) {
                    initialFilters.cities.allItems[value] = { id: value, value, text };
                }
            }

            if(vacancy.jobType) {
                const { id, text } = vacancy.jobType;
                if(!initialFilters.jobTypes.allItems[id]) {
                    initialFilters.jobTypes.allItems[id] = { id, text };
                }
            }

            if(vacancy.job_experience) {
                const { id, text } = vacancy.job_experience;
                if(!initialFilters.jobExperiences.allItems[id]) {
                    initialFilters.jobExperiences.allItems[id] = { id, text };
                }
            }

            vacancy.tags?.forEach((tag) => {
                if(!initialFilters.tags.allItems[tag.id]) {
                    initialFilters.tags.allItems[tag.id] = {
                        id  : tag.id,
                        text: tag.text
                    };
                }
            });
        });

        return initialFilters;
    });

    // Load initial filters from URL params or localStorage if not reset
    useEffect(() => {
        if(typeof window === 'undefined' || wasReset) {
            return;
        }

        const searchParams = new URLSearchParams(window.location.search);
        const hasUrlParams = URL_FILTER_PARAMS.some((param) => searchParams.has(param));

        if(hasUrlParams) {
            // Load from URL parameters
            setFilters((currentFilters) => {
                const newFilters = { ...currentFilters };

                Object.entries(FILTER_URL_MAPPING).forEach(([filterKey, urlKey]) => {
                    const values = searchParams.get(urlKey)?.split(',') || [];
                    const filterGroup = newFilters[filterKey as keyof IFilters];

                    values.forEach((value) => {
                        if(filterGroup.allItems[value]) {
                            filterGroup.selectedItems[value] = filterGroup.allItems[value];
                        }
                    });
                });

                return newFilters;
            });
        } else {
            // Try to load from localStorage
            const storedFilters = loadFilters();
            if(storedFilters) {
                setFilters((currentFilters) => convertStoredFiltersToFilters(storedFilters, currentFilters)
                );
            }
        }
    }, [wasReset]); // Re-run if wasReset changes

    // Save to localStorage when filters change
    useEffect(() => {
        if(typeof window === 'undefined') {
            return;
        }
        saveFilters(filters);
    }, [filters]);

    const toggleFilter = (filterType: keyof IFilters, id: string | number) => {
        if(typeof window === 'undefined') {
            return;
        }

        setFilters((prevFilters) => {
            const filterGroup = prevFilters[filterType];
            const newSelectedItems = { ...filterGroup.selectedItems };

            if(newSelectedItems[id]) {
                delete newSelectedItems[id];
            } else {
                newSelectedItems[id] = filterGroup.allItems[id];
            }

            const newFilters = {
                ...prevFilters,
                [filterType]: { ...filterGroup, selectedItems: newSelectedItems }
            };

            // Update URL
            const searchParams = new URLSearchParams(window.location.search);

            Object.entries(FILTER_URL_MAPPING).forEach(([filterKey, urlKey]) => {
                const selectedIds = Object.keys(newFilters[filterKey as keyof IFilters].selectedItems);

                selectedIds.length > 0
                    ? searchParams.set(urlKey, selectedIds.join(','))
                    : searchParams.delete(urlKey);
            });

            const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;

            window.history.pushState({}, '', newUrl);

            return newFilters;
        });
    };

    const resetFilters = () => {
        if(typeof window === 'undefined') {
            return;
        }

        // Set reset flag
        setWasReset(true);

        // Clear all selected items
        setFilters((prevFilters) => {
            const resetFilters = Object.keys(prevFilters).reduce((acc, key) => ({
                ...acc,
                [key]: { ...prevFilters[key as keyof IFilters], selectedItems: {} }
            }), {} as IFilters);

            // Clear URL parameters
            const searchParams = new URLSearchParams(window.location.search);

            URL_FILTER_PARAMS.forEach((param) => searchParams.delete(param));
            const newUrl = window.location.pathname;

            window.history.replaceState({}, '', newUrl);

            // Clear localStorage
            localStorage.removeItem('vacancies_filters');

            return resetFilters;
        });
    };

    const activeFilters = useMemo(() => {
        return Object.entries(filters).flatMap(([filterType, group]) => Object.entries(group.selectedItems).map(([id, item]) => ({
            id,
            type : FILTER_URL_MAPPING[filterType as keyof typeof FILTER_URL_MAPPING],
            label: (item as { text?: string }).text || id
        }))
        );
    }, [filters]);

    const filtersCount = useMemo(() => Object.values(filters).reduce((count, group) => count + Object.keys(group.selectedItems).length, 0
    ), [filters]
    );

    return { filters, filtersCount, activeFilters, toggleFilter, resetFilters };
};
