import React from 'react';

import { TVacancy } from '../../types';

import Button from '@/components/button-like/button';
import { useClassnames } from '@/hooks/use-classnames';
import { pluralize } from '@/utils';

interface IVacanciesFilterButtonsProps {
    isMobileFilterVisible: boolean,
    setIsMobileFilterVisible: (value: boolean) => void,
    filtersCount: number,
    filteredVacancies: Array<TVacancy>,
    resetFilters: () => void
}

export const VacanciesFilterButtons: React.FC<IVacanciesFilterButtonsProps> = ({
    isMobileFilterVisible,
    setIsMobileFilterVisible,
    filtersCount,
    filteredVacancies,
    resetFilters
}) => {
    const cn = useClassnames();

    return (
        <div className={cn('vacancies__filter-buttons')}>
            {!isMobileFilterVisible ? (
                <Button
                    variant="secondary"
                    type="button"
                    className={cn('vacancies__button', 'vacancies__filter-button', {
                        'vacancies__button_visible': !isMobileFilterVisible
                    })}
                    label="Фильтровать"
                    isBlock={true}
                    onClick={() => {
                        window.scrollTo(0, 0);
                        setIsMobileFilterVisible(!isMobileFilterVisible);
                    }}
                >
                    Фильтровать
                    {filtersCount ? (
                        <span className={cn('vacancies__button-count')}>{filtersCount}</span>
                    ) : null}
                </Button>
            ) : (
                <React.Fragment>
                    <Button
                        type="button"
                        className={cn('vacancies__button', {
                            'vacancies__button_visible': isMobileFilterVisible
                        })}
                        disabled={!filteredVacancies.length}
                        isBlock={true}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            setIsMobileFilterVisible(!isMobileFilterVisible);
                        }}
                    >
                        {!filteredVacancies.length
                            ? 'Нет вакансий'
                            : `Смотреть ${filteredVacancies.length} ${pluralize(
                                filteredVacancies.length,
                                'вакансию',
                                'вакансии',
                                'вакансий'
                            )}`}
                    </Button>
                    <Button
                        variant="secondary"
                        type="button"
                        className={cn('vacancies__button', 'vacancies__reset-button', {
                            'vacancies__button_visible': isMobileFilterVisible
                        })}
                        isBlock={true}
                        onClick={() => {
                            window.scrollTo(0, 0);

                            if(filtersCount === 0) {
                                setIsMobileFilterVisible(!isMobileFilterVisible);

                                return;
                            }
                            resetFilters();
                        }}
                    >
                        {filtersCount ? 'Сбросить' : 'Закрыть'}
                        {filtersCount ? (
                            <span className={cn('vacancies__button-count')}>{filtersCount}</span>
                        ) : null}
                    </Button>
                </React.Fragment>
            )}
        </div>
    );
};
